// Buttons
//––––––––––––––––––––––––––––––––––––––––––––––––––

.btn {
    margin-top: 20px;
    padding: 10px 30px;
    border-radius: 3px !important;
}

.btn--tag {
    color: @gray;
    border: 1px solid @gray;

    &:hover {
        background: @maingreen;
        border-color: @maingreen;
    }

    &.checked {
        background: @maingreen;
        border-color: @maingreen;
    }
}

.btn--primary.dw-mod {
    text-transform: uppercase;
    border: 2px solid;
    height: auto;
    line-height: 20px;
    padding: 10px 25px;

    &:hover {
        background: @maingreen;
        color: @white;
        border: 2px solid @maingreen;
    }

    &:active,
    &:focus {
        background-color: transparent !important;
        color: @maingreen !important;
        border-color: @maingreen !important;
        outline: none;
    }
}

.btn--secondary.dw-mod {
    text-transform: uppercase;
    border: 2px solid @white;
    height: auto;
    line-height: 20px;
    padding: 10px 25px;

    &:hover {
        color: #333;
        background: @white;
    }

    &:active,
    &:focus {
        color: #333;
        background: @white;
    }
}

.btn--line-height:not(.btn--sm) {
    line-height: inherit !important;
    height: auto !important;
}

//Load more button
.btn--load-more {
    width: auto;
    margin: 10px auto;
    height: auto;
    padding: 18px 15px !important;

    &:before {
        content: '\f0fe';
        font-family: FontAwesome;
        font-size: 17px;
        margin-right: 10px;
    }

    &:active,
    &:focus {
        background-color: transparent !important; 
        color: @maingreen !important;
        border-color: @maingreen !important;
        outline: none;
    }
}


//Buy button
.product__price-buy-button.dw-mod {
    margin-top: 4px;
}