/**
 * Basic typography style for text
 * Warning: These styles overrides without the default .dw-mod class, as they all target the most basic required elements
 */

//Do NOT make .dw-mod for these, as they are basic html tags

//Header styles
h1 {
    text-transform: uppercase;
}

//Sub header styles
h2 {
    text-transform: uppercase;
}

h3 {
    font-size: 18px;
}

.light-font {
    font-family: "Palanquin";
    font-weight: 300;
}

.u-color-green-light {
    h2 {
        font-family: "Palanquin";
        font-weight: 300;
        color: @maingreen;
        text-transform: none;
    }
}

//Body styles
body {

}

//link styles
a {

}

a:hover, a:active, a:focus {
   
}

//Other
.help-text {
    font-size: .9em;
}

.multiple-paragraphs-container .dw-mod {
    @media (max-width: @reflex-sm) {
        h1 {
            span {
                font-size: 38px !important;
            }
        }
    }
}