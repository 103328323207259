﻿// -----------------------------------------------------------------------------
// Here you can customize the styles. Most of the already available classes are shown here.
// -----------------------------------------------------------------------------

//Main navigation
.main-navigation.dw-mod {
    padding: 0;

    @media (min-width: @reflex-sm) {
        padding: 15px;
    }

    @media (min-width: @reflex-lg) {
        padding: 0;
    }
}

.menu-nav {
    position: absolute;
    right: 0;
    top: 0;

    @media (min-width: @reflex-sm) {
        top: -30px;
    }

    @media (min-width: @reflex-lg) {
        float: left;
        position: initial;
    }

    .menu.dw-mod {
        padding-top: 25px;

        @media (min-width: @reflex-lg) {
            padding-top: 35px;
        }
    }
}

.top-container__center-container {
    .search {
        float: left;
        margin-left: 25px;

        @media (min-width: @reflex-sm) {
            position: absolute;
            right: 55px;
            top: -29px;
        }

        @media (min-width: @reflex-lg) {
            top: 0;
        }

        i {
            font-size: 20px;
            margin-left: 30px;

            @media (min-width: @reflex-lg) {
                font-size: 18px;
            }
        }

        .menu.dw-mod {
            padding-top: 30px;

            @media (min-width: @reflex-lg) {
                padding-top: 35px;
            }
        }

        // .menu__item--top-level {

        //     &:hover {
        //         border-bottom-color: transparent !important;
        //     }
        // }
    }
}
//Menu item specifics
// .menu.dw-mod {
//     padding-top: 35px;
// }

.menu__item--top-level.dw-mod {
    padding: 0 15px;
    border: none;

    &:hover {
        border: none;
    }

    // &:hover {
    //     border-color: transparent; 

    //     @media (min-width: @reflex-lg) {
    //         border-bottom-color: @darkgreen;
    //     }

    //     &:after {
    //         color: @darkgreen;
    //     }
    // }

    // @media (min-width: @reflex-lg) {
    //     border-top: 3px solid #fff;
    //     border-bottom: 3px solid #fff;
    // } 
}

.is-mega.dw-mod:after {
    content: '\f0d7';
}
//Mega menu
.mega-menu {
    padding: 30px !important;
    box-shadow: none;

    .mega-menu__link.dw-mod {
        font-family: 'Palanquin';
        padding: .5em .4em;
    }


    .menu.dw-mod {
        padding-top: 0;
    }

    .mega-menu__header-container.dw-mod {
        margin: 0;
    }

    .mega-menu__header-container__text.dw-mod {
        font-family: "Palanquin Dark";
        margin-bottom: 0;

        &:hover {
            color: @darkgreen;
        }
    }

    &.small-bg {
        left: auto;
        max-width: 275px;
        margin-left: -15px;
    }

    .splitted {
        width: 100%;
        padding: 10px 0;

        &:first-child {
            padding-top: 0;
        }
    }
}

.menu__link.dw-mod {
}

.menu__item.dw-mod {
}

.menu__item.dw-mod:after {
}

.menu__item--active.dw-mod, .menu__item.dw-mod:hover, .menu__item.dw-mod:focus, .menu__item.dw-mod:active {
    border: none !important;
    &:after {
    }
}

.menu__link--active.dw-mod, .menu__link.dw-mod:hover, .menu__link.dw-mod:focus, .menu__link.dw-mod:active {
}
//Search
.top-micro-search.dw-mod {
    padding-top: 0 !important;
    width: 100vw;
    top: 107%;
    padding-bottom: 0;
    left: 6px;

    @media (min-width: @reflex-sm) {
        top: 132%;
        left: -7px;
    }

    @media (min-width: @reflex-lg) {
        top: 105%;
        width: 370px;
        right: 85px;
        left: auto;
    }

    .typeahead {
        margin: 0;
    }
}

.typeahead-search-field {

    input[type=text] {
        height: 45px !important;
    }


    .dropdown__item--seperator.dw-mod {
        border-bottom: 0;
        border-top: 1px solid #e4e4e4;
        padding: 0.5em;
        text-transform: none;
        font-family: Palanquin;

        &:hover {
            background: @lightgreen;
        }

        a {
            margin-top: 8px;
        }

        img {
            //min-width: 75px;
        }

        .btn {
            margin-top: 0;
        }

        i {
            margin-left: 0;
        }
    }

    .dropdown__item--not-selectable.dw-mod {
        padding-top: 15px;
        border-top: 1px solid #e4e4e4;

        &:hover {
            background: @lightgreen;
        }

        .btn {
            padding: 20px 0;
            margin-top: 0; 

            &:hover {
                background: @maingreen;
                color: #FFF;
            }
        }
    }

    .no-results {
        padding: 0.5em;
        text-transform: none;
        font-family: "Palanquin";
    }
}

.js-typeahead-enter-btn {
    margin-top: 0 !important;
    background-color: @maingreen !important;
    color: @white !important;
    border-color: @maingreen !important;
}

.typeahead-mobile__search-field.dw-mod {
    input {
        width: 70%;
    }
    .btn {
        line-height: 14px;
    }
}
