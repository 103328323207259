﻿// -----------------------------------------------------------------------------
// Here you can customize the styles. Most of the already available classes are shown here.
// -----------------------------------------------------------------------------


.logo.dw-mod {
    float: left;
    width: 50%;

    @media (min-width: @reflex-lg) {
        width: auto;
        float: none;
        margin-right: 65px;
        padding-left: 0;
    }
}

.logo--mobile.dw-mod {
    width: 100%;
}

.logo-container {

}