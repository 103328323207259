﻿.find-store {
    background: @white;
    padding: 45px 40px;
    margin: 35px 0;

    @media (max-width: @reflex-xs) {
        margin-bottom: 0;
    }

    .search-form {
        margin-bottom: 25px;
        width: 95%;
        margin: 0 auto;
        position: relative;

        .button-primary {
            background: transparent;
            border: none;
            font-size: 20px;
            position: absolute;
            right: 1px;
            top: 6px;
            color: @darkgreen;

            &:active,
            &:focus,
            &:hover {
                outline: none;
            }
        }

        .search-field {
            border: none;
            border-bottom: 2px solid @darkgreen;
            margin-bottom: 10px;
            font-size: 18px;


            &:focus,
            &:active {
                outline: none;
            }

            &::-webkit-input-placeholder {
                font-size: 18px;
                color: #333;
            }

            &::-moz-placeholder {
                font-size: 18px;
                color: #333;
            }

            &:-ms-input-placeholder {
                font-size: 18px;
                color: #333;
            }

            &:-moz-placeholder {
                font-size: 18px;
                color: #333;
            }
        }

        .address {
            text-align: center;
        }

        .formatted-address {
            text-transform: uppercase;
            font-size: 13px;
        }
    }

    .stores {
        padding: 0 25px;
        margin-top: 30px;

        @media (max-width: @reflex-md) {
            padding: 0;
        }
    }

    .row-headings {
        text-align: left;

        @media (max-width: @reflex-xs) {
            display: none;
        }
    }

    .search-filter {
        list-style: none;
        padding-left: 0;
        text-align: center;
        margin-bottom: 45px;
        margin-top: 35px;
        display: none;

        li {
            display: inline-block;
            margin-right: 20px;
            margin-bottom: 5px;
            position: relative;
            font-size: 14px;
            text-transform: uppercase;

            label {
                cursor: pointer;
                margin-right: 5px;

                .check {
                    border-radius: 2px;
                    margin-right: 7px;
                    border: 1px solid #000;
                    padding: 0 3px;
                    font-size: 9px;
                    position: absolute;
                    left: -10px;
                    top: 2px;
                    width: 16px;
                    height: 16px;

                    &:before {
                        content: '\f0c8';
                        font-family: FontAwesome;
                        color: #FFF;
                    }
                }

                input[type=checkbox] {
                    visibility: hidden;

                    &:checked + .check:before {
                        content: '\f00c';
                        font-family: FontAwesome;
                        color: #000;
                        position: absolute;
                        font-size: 12px;
                        left: 1px;
                        top: -2px;
                    }
                }
            }
        }
    }

    .stores-list {
        margin-top: 20px;
        list-style: none;
        padding: 0;
        text-align: left;


        .store-name {
            font-size: 15px;
        }


        .phonenumber {
            font-size: 14px;

            &:before {
                content: '\f095';
                margin-right: 5px;
            }
        }

        > div {
            padding-bottom: 15px;
            margin-bottom: 25px;
            border-bottom: 2px solid @lightgreen;
            overflow: hidden;

            &:last-child {
                border-bottom: 0;
            }

            > div {
                margin: 5px 0;
            }

            span {
                display: inline-block;
            }

            strong {
                display: inline-block;
                vertical-align: top;
                margin-right: 30px;
                min-width: 100px;
            }

            .button {
                display: inline-block;
                float: left;
                margin: 15px 0 0 15px;
                padding: 7px 25px 7px 20px;
                border: 1px solid black;
                font-size: 14px;
                text-transform: uppercase;

                &:hover {
                    text-decoration: none;
                    color: white;
                    background: black;
                }

                &:before {
                    content: '\f015';
                    font-family: FontAwesome;
                    margin-right: 8px;
                }
            }

            .tags {
                font-size: 0;
                margin-top: 15px;

                b {
                    font-size: 16px;
                    margin-right: 15px;
                }

                .tag {
                    margin-right: 7px;
                    font-size: 12px;

                    &:not(:last-child):after {
                        content: '-';
                        margin-left: 7px;
                    }
                }
            }
        }
    }
}

.webshops {
    padding: 45px 40px;
    background: @white;
    margin: 35px 0;

    @media (max-width: @reflex-sm) {
        padding: 0 25px;
    }

    @media (max-width: @reflex-xs) {
        margin: 0;
    }

    ul {
        list-style: none;
        width: 80%;

        @media (max-width: @reflex-xs) {
            width: 100%;
        }

        li {
            padding: 30px 0;
            margin-bottom: 0;
            border-bottom: 2px solid @lightgreen;
            position: relative;

            a {

                &:after {
                    content: '\f101';
                    font-family: FontAwesome;
                    font-size: 24px;
                    border: 1px solid @maingreen;
                    padding: 7px 10px;
                    border-radius: 3px;
                    position: absolute;
                    right: 0;
                    transition-duration: 200ms;
                }

                &:hover {

                    &:after {
                        color: @white;
                        background: @maingreen;
                    }
                }
            }

            .btn {
                float: right;
                margin-bottom: 0;
                margin-top: -10px;
                padding: 18px 30px;
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }
}
