﻿// // -----------------------------------------------------------------------------
// // Here you can customize the styles. Most of the already available classes are shown here.
// // -----------------------------------------------------------------------------


.menu-mobile__trigger--back {
    top: 39px;
}

.menu-mobile__submenu {
    .menu-mobile__item:nth-child(1) {
        background: transparent;
        .menu-mobile__link {
            border-bottom: none !important;
        }
        .menu-mobile__trigger--back {
            &:before {
                margin: -32px auto 1px;
                font-size: 18px;
            }
        }
    }
    .menu-mobile__item:nth-child(2) {
        margin-top: -15px;
    }
}

// //Main navigation
// .mobile-navigation.dw-mod {
//     position: fixed;
//     top: 0;
//     right: -250px;
//     bottom: 0;
//     left: auto;
//     transition: right 0.2s;

//     .grid__col--line-top {
//         border-top: 0;
//     }

//     .grid__col-12 {
//         padding: 0;
//     }
// }

// .mobile-nav-trigger:checked ~ .mobile-navigation {
//     right: 0;
//     left: auto;
// }

// .mobile-nav-trigger:checked ~ .site {
//     right: 250px;
//     left: auto;
// }

// //Sub menu
// .menu-mobile__submenu {

//     .menu-mobile__item {
//         background: @lightgreen;
//         border-bottom: 1px solid #bcdec5 !important;

//         &:last-child {
//             border-bottom: none !important;
//         }

//         .menu-mobile__link {
//             color: @darkgray;
//             font-family: "Palanquin";
//             font-weight: 500;
//             font-size: 13px;
//             width: 100% !important;
//             padding: 1em .9em !important;

//             &:last-child {
//                 border-bottom: none;
//             }
//         }

//         &.is-submenu {

//             .btn-mobilemenu {
//                 background: transparent;
//                 padding: 12px 15px;

//                 &:after {
//                     color: @maingreen;
//                 }
//             }

//             &.menu-mobile__item--open {
//                 border-bottom: 2px solid #EDF4EF !important;
//             }
//             //Sub level three
//             .menu-mobile__submenu {
//                 border-bottom: none;

//                 .menu-mobile__item {
//                     background: @white;
//                     font-size: 12px;
//                     font-weight: 300;
//                     border-bottom: 1px solid #eee !important;
//                 }
//             }
//         }
//     }
// }

// //Menu item specifics

// .menu-mobile__item.dw-mod {
//     border-bottom: 2px solid @lightgreen;
//     float: none;

//     .menu-mobile__link.dw-mod {
//         padding: 1em 0.5em;
//         width: 100%;
//         border-bottom: none;

//         &.is-submenu {
//             width: 83%;
//         }
//     }

//     .btn-mobilemenu {
//         padding: 17px 15px;
//         background: @maingreen;
//         position: absolute;
//         right: 0;
//         z-index: 10;
//         top: 0;

//         &:after {
//             font-family: FontAwesome;
//             content: '\f107';
//             color: @white;
//             font-size: 14px;
//         }
//     }
// }



// .menu-mobile__link--open {

//     &:before {
//         top: 10px;
//     }
// }

// .menu-mobile__item--open {
//     border-bottom: none;
// }

// .menu-mobile__link--closed:before,
// .menu-mobile__link--open:before {
//     display: none;
// }

// .menu-mobile__link--active.dw-mod:not(.is-submenu) {
//     color: @maingreen;
//     width: 100% !important;
// }

// .mobile-nav-trigger-button {
//     font-size: 24px !important;
//     padding-top: 30% !important;
//     padding-right: 10px !important;
// }

// .menu-mobile__item--active.dw-mod, .menu-mobile__item.dw-mod:hover, .menu-mobile__item.dw-mod:focus, .menu-mobile__item.dw-mod:active {
// }

// .menu-mobile__link--active.dw-mod, .menu-mobile__link.dw-mod:hover, .menu-mobile__link.dw-mod:focus, .menu-mobile__link.dw-mod:active {
// }
