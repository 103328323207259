//Breadcrumb

.breadcrumb.dw-mod {
    padding-top: 10px;
    padding-left: 10px;
}

.breadcrumb__item.dw-mod {
    font-size: 12px;
    font-weight: 300;


    a {
        color: #000;
    }

    &:last-child {
        a {
            color: #a8a8a8;
        }
    }
}

.breadcrumb__item.dw-mod:not(&:last-child) {

    &::after {
    }
}
