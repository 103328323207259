// -----------------------------------------------------------------------------
// Contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.header.mod {
    max-height: 45px;
}

.header-menu__link.mod {

}

.header-top.dw-mod {
    padding: 25px 0;
}
