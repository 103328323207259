//Cart

.cart-table.dw-mod {
  @media (max-width: @reflex-md) {
    .form__field-group {
      .btn {
        color: #920202 !important;
        margin-top: 0;
        padding: 2px 7px;
        font-size: 12px;
        margin-left: 5px;
      }
    }
    .u-hidden {
        visibility: hidden;
    }
    td {
        border: none !important;
    }
    tr {
        float: left;
        padding: 15px;

        &:nth-child(3n+1) {
            width: 60%;
        }
    }
    .table__row--no-border {
        width: 30%;

        .cart-table__image.dw-mod {
            width: 0;
            padding: 0;
            display: none;
        }
    }
    .total-price {
        font-size: 18px;
        font-weight: bold;
    }
  }
}

.cart-table__image.dw-mod {
}

.cart-table__quantity.dw-mod {
}

.cart-table__actions.dw-mod {
}

.cart-table__price.dw-mod {
}

.cart-summary.dw-mod {
}

.cart-summary__totals.dw-mod {
}

.cart-orderline__cell {
  .btn {
    color: #920202 !important;
    margin-top: 0;
    padding: 2px 7px;
    font-size: 12px;
    margin-left: 5px;
  }
}

.voucher-input {
  div {
    float: left;
    max-width: 150px;
    margin-right: 5px;
  }
  input {
    max-width: 150px;
  }
}

.btn-voucher {
  padding: 6px 10px !important;
  min-width: 100px;
  margin-top: 0;
  float: left;
}

.table--responsive-order {
    background-color: white;
    margin-bottom: 0 !important;
}

//Shipping in checkout
#Block__Shipping {
  .form__field-group {
    label {
      &:before {
        margin-top: 3px;
      }
      &:after {
        top: 8px;
      }
    }
  }
  .shipping-option-title {
    font-weight: bold;
    display: inline-block;
    margin-bottom: 5px;
    margin-left: 5px;
  }
  
  .shipping-option-description {
    display: block;
    margin-left: 27px;
  }
}
