//Mini cart

.floating-mini-cart.dw-mod {

}

.mini-cart.dw-mod {

}

.mini-cart__counter.dw-mod {
    background-color: @maingreen;
    line-height: 13px;
}

.mini-cart__counter--lg.dw-mod {

}

.mini-cart-dropdown.dw-mod {

}

.mini-cart-dropdown__inner.dw-mod {

}

.mini-cart-dropdown__body.dw-mod {

}

.mini-cart-table.dw-mod {

}

.mini-cart-orderlines.dw-mod {

}

.mini-cart-orderlines__name.dw-mod {

}

.mini-cart-totals.dw-mod {

}

.mini-cart-orderlines__footer.dw-mod {

}

.mini-cart__footer.dw-mod {
    .btn--secondary {
        border-color: #920202;
        color:#920202;

        &:hover {
            background-color:#920202;
            color: white;
        }
    }
}

.mini-cart-update .mini-cart__counter.dw-mod {

}