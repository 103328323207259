﻿// -----------------------------------------------------------------------------
// Here you can customize the styles. Most of the already available classes are shown here.
// -----------------------------------------------------------------------------


//Site layout
.site-container.dw-mod {
  
}

.page {
    //margin-top: 0 !important;
    background: @lightgreen;

    // @media (min-width: @reflex-lg) {
    //     margin-top: 197px !important;
    // }
}

.grid__cell-img {
    display: block;
}
//The content container
.content-container.dw-mod {
    background: inherit;
}

//The multiple paragraphs container
.multiple-paragraphs-container.dw-mod {
    p a {
        color: @maingreen;
    }
}

.paragraph-container__grid--bleed-y {
    @media(max-width: @reflex-md) {
        padding-left: 1em;
        padding-right: 1em;
    }
}

//The paragraph container
.paragraph-container {
    padding-top: 2em;
    padding-bottom: 2em;
    /*padding-left: 0.5em;
    padding-right: 0.5em;*/
    @media(max-width: @reflex-md) {
        padding-top: 2.5em;
        padding-bottom: 2.5em;
        padding-left: 1em;
        padding-right: 1em;
    }
}

//Container with background image
.center-container--with-background-image {
    min-height: 450px;

    h1 {
        font-size: 32px;
        line-height: 40px;
    }

    p {
        font-size: 16px;
        line-height: 22px;
    }
}

.item-center {
    margin: 0 auto;
}

.header-and-blocks {

    .paragraph-container {
        padding-top: 1em;

        &:first-child {
            padding-top: 4.5em;
            padding-bottom: 0;
        }
    }
}

// Color utility modifiers
.u-brand-color-one, .u-brand-color-one h1, .u-brand-color-one h2, .u-brand-color-one i {
}

.u-brand-color-two, .u-brand-color-two h1, .u-brand-color-two h2, .u-brand-color-two i {

}


// Background-color utility modifiers
.u-color-dark-green--bg {
    background: @maingreen;
}

.u-brand-color-one--bg {
}

.u-brand-color-two--bg {

}