//Product header
.product-group-header,
.product-group-count {
  text-align: center;
  text-transform: uppercase;
}

.product-group-header {
  margin-bottom: 15px;
}

.product-group-count {
  text-transform: none;
  margin-bottom: 50px;
  font-family: "Palanquin";
  font-weight: 300;
  color: #222;
  font-size: 18px;
}

//Product list
.product-list.dw-mod {
  margin-top: 1.2em;

  > div {
    padding: 1em;
  }
}

.product-list__condensed-title.dw-mod {
}

//The List view
.product-list__list-item.dw-mod {
  .variant-heading {
    margin-bottom: 10px;
    font-weight: bold;
  }

  .labels {
    top: 0;
  }

  .product__name {
    float: left;
    text-align: left;

    @media (max-width: @reflex-md) {
      text-align: center;
      float: none;
    }
  }

  .product-list__list-item__right {
    .help-text {
      text-align: center;

      @media (min-width: @reflex-lg) {
        text-align: left;
      }
    }
  }
}

.product-list__list-item__left.dw-mod {
  border: none;
}

.product-list__list-item__right.dw-mod {
  border: none;
}

.product-list__grid-item.dw-mod {
  transition: none !important;
  &:hover {
    .product-image {
      border-color: #fff !important;
    }
    .product-list__grid-item__price-info.dw-mod,
    .product-list__grid-item__image.dw-mod {
      border-color: #fff !important;
    }
  }

  .product-list__grid-item__price-info.dw-mod {
    h6 {
      font-size: 18px;
    }
  }
}

.product-card {
  border: 1px solid transparent;
  padding: 15px;
  transition: all 0.4s;
  background: @white;

  &:hover {
    border-color: @maingreen;
  }
}

.product-image {
  padding-top: 10px;
  border-color: #fff;
  transition: none !important;
}

.promotion-product {
  .product-list__grid-item__price-info.dw-mod {
    h2 {
      font-size: 18px;
      margin-top: 25px;
      color: #333;
      margin-bottom: 5px;
      text-transform: none;

      @media (min-width: @reflex-md) {
        font-size: 24px;
      }
    }
  }
}

.labels {
  position: absolute;
  background-color: transparent !important;
  z-index: 1;
  top: -14px;
  right: 0;
  width: 100%;
  left: auto;

  @media (min-width: @reflex-sm) {
    width: 30%;
    top: -13px;
  }

  @media (min-width: @reflex-lg) {
    width: 18%;
    top: -13px;
  }

  &.list-view {
    position: absolute;
    top: 0;
    left: 14px;
    z-index: 10;
    width: 94%;
    text-align: center;

    @media (max-width: @reflex-md) {
      top: 0;
    }
  }

  .product__flag {
    color: @white;
    font-family: "Palanquin Dark";
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    padding: 5px 15px;
    text-align: center;
    border-radius: 4px;
    z-index: 2;
    display: inline-block;
    margin: 0 auto;

    &.news {
      background: @limegreen !important;
    }

    &.campaign {
      background: @brickred !important;
    }

    &.u-hidden {
      display: none;
    }
  }
}
//The Grid view
.product-list__grid-item.dw-mod {
}

.product-list__grid-item__image.dw-mod {
  background: @white;
  border: none;
  /*height: 280px;
    max-height: 280px;*/
  overflow: hidden;
}

.product-list__grid-item__price-info.dw-mod {
  border: none;
  height: unset;
  h3 {
    margin-top: 20px;
    color: #333;
    margin-bottom: 5px;
  }
}

.product-list__grid-item__footer.dw-mod {
}
//The details view
.product-list__details-item.dw-mod {
}

@media (max-width: @reflex-xs) {
  .product-list__details-item.dw-mod {
  }
}

.product-list__details-item__left.dw-mod {
}

.product-list__details-item__right.dw-mod {
}

//Buttons for switching views
.collection {
  .btn--tag {
    background: transparent;
    color: @maingreen;
    border-color: @maingreen;

    &:hover {
      background: @maingreen;
      color: @white;
    }
  }
}

//Filters
.filters {
  margin-top: 160px;

  .expand-container {
    background: transparent;
    border: none;

    .facets-container__header {
      border: none;
      font-family: "Palanquin Dark";
      font-weight: 600;
      font-size: 18px;
      text-transform: uppercase;
      color: @maingreen;
      pointer-events: none;
      padding-left: 6px;

      &:after {
        display: none;
      }
    }

    .facets-container__list {
      max-height: none !important;
      font-size: 16px;
      font-weight: 300;

      .checkbox-facet.dw-mod {
        &:before {
          border-color: @maingreen;
        }
      }

      input[type="checkbox"].form__control:checked + label {
        &:after {
          content: "\f14a";
          font-family: FontAwesome;
          position: absolute;
          top: 0;
          color: @maingreen;
          left: 0;
          font-size: 23px;
          height: unset;
          width: unset;
          border: none;
          transform: none;
        }
      }

      .checkbox-facet__label.dw-mod {
        flex: unset;
        margin-right: 10px;
      }

      .checkbox-facet__count.dw-mod {
        color: #333;
      }
    }
  }
}

.filters-mobile {
  .dropdown {
    background: #fcfffd;
    border: 1px solid @maingreen;
    margin-bottom: 0;
    border: none;

    .dropdown__header {
      font-family: "Palanquin Dark";
      font-weight: 600;
      font-size: 16px;
      text-transform: uppercase;
      color: @maingreen;
      border: 1px solid @maingreen;
      padding: 12px 15px;
      height: auto;

      &:after {
        font-size: 26px;
      }
    }

    .dropdown__content {
      position: relative;
      box-shadow: none;
      max-height: none;
      border: 1px solid @maingreen;
      border-top: none;
      padding: 17px 15px 10px 15px;

      > div {
        position: relative;
      }

      label {
        cursor: pointer;
        position: absolute;
        width: 18px;
        height: 18px;
        top: 0;
        left: 0;
        background: transparent;
        border: 1px solid @maingreen;
        border-radius: 2px;

        &:after {
          opacity: 0;
          content: "\f14a";
          font-family: FontAwesome;
          position: absolute;
          top: -2px;
          color: @maingreen;
          left: -2px;
          font-size: 23px;
        }
      }

      .name {
        margin-left: 10px;
      }

      .product-count {
        margin-left: 5px;
      }

      input[type="checkbox"] {
        visibility: hidden;

        &:checked + label:after {
          opacity: 1;
        }
      }

      .disabled {
        display: none;
      }
    }
  }
}

.expand-trigger:checked
  + .expand-container
  .expand-container__content--limit-height {
  max-height: none;
}

.facet-selector {
  background-color: @maingreen !important;
  color: @white !important;
  width: 97%;
  margin: 15px auto;
  padding: 25px !important;
  font-family: "Palanquin Dark";
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  border: none !important;
}

//Selected filters
#selectedFacets {
  .btn--tag {
    color: @maingreen;
    background: transparent;
    border: none;
    margin: 10px 15px 10px 0;

    i {
      margin-left: 5px;
    }

    &:hover {
      background: transparent;
      border: none;
      color: @darkgreen;
    }

    &:active,
    &:focus {
      border: none;
      outline: none;
    }
  }
}

.btn--reset-filters {
  background: @maingreen;
  color: @white;
  border: 1px solid @maingreen;
  padding: 0 15px;

  i {
    margin-left: 5px;
  }

  &:hover {
    background: @darkgreen;
    border: 1px solid @darkgreen;
  }
}
