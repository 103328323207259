﻿// -----------------------------------------------------------------------------
// Here you can customize the styles. Most of the already available classes are shown here.
// -----------------------------------------------------------------------------

.footer.dw-mod {
    padding-top: 0;

    .center-container {
        padding: 3em 0 4em 0;
    }

    .footer__heading.dw-mod {
        color: @maingreen;
        font-family: 'Palanquin Dark';
        font-weight: 500;
        margin-bottom: 1.2rem;
        text-transform: uppercase;
    }

    a {
        color: #242424;

        &:hover {
            color: @maingreen;
        }
    }

    .fa-facebook-square {
        color: #3b5998;
    }

    .grid {

        @media(max-width: @reflex-md) {
            padding: 0 1em;
        }

        > div {

            &:first-child {
                width: 50%;

                @media (min-width: @reflex-sm) {
                    width: 25%;
                }

                @media(min-width: @reflex-md) {
                    width: 16.66666667%;
                }
            }

            &:nth-child(2) {
                width: 50%;

                @media (min-width: @reflex-sm) {
                    width: 25%;
                }

                @media(min-width: @reflex-md) {
                    width: 16.66666667%;
                }
            }
            &:nth-child(3) {
                width: 100%;

                @media (min-width: @reflex-sm) {
                    width: 25%;
                }

                @media(min-width: @reflex-md) {
                    width: 16.66666667%;
                }
            }
            &:last-child {
                width: 100%;

                @media (min-width: @reflex-sm) {
                    width: 25%;
                }

                @media(min-width: @reflex-md) {
                    width: 16.66666667%;
                }
            }
        }
    }

    .brand-list-heading {
        padding-left: 0;

        @media (min-width: @reflex-md) {
            padding-left: 15px;
        }
    }

    .brands-list {

        .brand-images {
            flex-direction: row;
            float: left;
            width: 50%;
            max-height: 120px;
            padding: 15px;

            img {
                max-height: 110px;
                margin: 0 auto;
            }

            @media (min-width: @reflex-sm) {
                max-height: 40px;
                padding: 10px;
    
                img {
                    max-height: 40px;
                    margin: 0 auto;
                }

            }

            @media (min-width: @reflex-md) {
                min-height: 130px;
                padding: 20px;
                padding-left: 0;

                img {
                    display: block;
                    max-width: 100%; 
                    max-height: unset;
                }
            }



            &:nth-child(3),
            &:nth-child(4) {
                margin-top: 10px;
            }
        }
    }

    .footer-bottom {
        background: @maingreen;
        color: @white;
        padding: 1em;

        p {
            margin-bottom: 0;
            font-size: 13px;
        }
    }
}
