//Base classes for Ecommerce

//Top micro search
.top-micro-search.dw-mod {
}

//Product
.product {
    background: @white;
    padding: 35px;

    @media (max-width: @reflex-md) {
        padding: 15px;
    }

    &__top {
        background-color: transparent !important;

        .center-container {
            max-width: 1270px;
            div:not(.carousel-next-btn):not(.carousel-prev-btn) {
                background-color: @white;
            }
        }
    }

    &__info {
        .grid:nth-child(2) {
            padding: 15px;
            
            @media (min-width: @reflex-md) {
                padding: unset;
            }
        }
    }

    &__title {
        padding-top: 25px;
        width: 100%;
        h1 {
            color: @maingreen !important;
        }
    }

    &__section {
        background-color: @white;
        padding: 30px;

        .center-container .grid {
            padding: 0 !important;
        }
        
    }

    .labels {
        top: -14px;
        right: 0;
        width: 100%;
        left: auto;

        @media (min-width: @reflex-sm) {
            width: 30%;
            top: -13px;
        }

        @media (min-width: @reflex-lg) {
            width: 18%;
            top: -13px;
        }
    }

    // .section-title {
    //     &:before {
    //         display: none;
    //     }
    // }

    // .introduction-text {
    //     font-size: 16px;
    // }

    // .variant-listing {
    //     h4 {
    //         font-size: 14px;
    //         font-weight: 400;
    //         margin-bottom: 15px;
    //     }

    //     table {
    //         th {
    //             border-top: none;
    //             color: @darkgreen;
    //             border-bottom: 1px solid #d8d8d8;
    //         }

    //         td {
    //             border: none;

    //             &:first-child {
    //                 font-weight: 600;
    //             }
    //         }
    //     }
    // }

    .product__thumbs.dw-mod {
        background: @white;


        .thumb-list__item {

            &:hover {
                border: 1px solid @light-gray;
            }
        }

        .thumb-list__item--active {
            border: 1px solid @light-gray;
        }
    }

    .product__title.dw-mod {
        margin-bottom: 15px;
    }

    .product__info.dw-mod {
        padding: 5px;

        @media (min-width: @reflex-md) {
            padding: 15px;
        }
    }
}

// .product__second-section {
//     background: @white;
//     margin-top: 35px;

//     .product__features.dw-mod,
//     .product__description {
//         padding: 35px;

//         @media (max-width: @reflex-md) {
//             padding: 15px;
//         }

//         .section-title {
//             &:before {
//                 border-top: none;
//                 height: 0;
//             }
//         }
//     }
//     /*.product__description {
//         text-align: center;

//         > div {
//             margin: 0 auto;
//         }
//     }*/
// }

@media (min-width: @reflex-md) {
    .product__image-container.dw-mod {
    }
}

.product__image-container__image.dw-mod {
}

.product__price-actions.dw-mod {
}

.product__price-actions__price.dw-mod {
}

.product__price-actions__actions.dw-mod {
}

.product__description.dw-mod {
}


//Item number
.item-number.dw-mod {
    color: #a8a8a8 !important;
    margin-bottom: 0.25em;
    font-size: 1.5rem;
    margin-top: 5px;
}
//Prices
.price.dw-mod {
}

.before-price.dw-mod {
}
//Favorites
.favorites.dw-mod {
}

.favorites-list.dw-mod {
}
//Sticker
.sticker.dw-mod {
}

.micro-sticker.dw-mod {
}
//Stock icon
.stock-icon.dw-mod {
}

.stock-icon--in.dw-mod {
}

.stock-icon--not.dw-mod {
}

.stock-icon--comming.dw-mod {
}

.variant-table.dw-mod {
    
    td {
        padding: 5px 0;
    }

    input[type=number] {
        width: 60x;
        max-width: 60px;
    }

    .btn--primary {
        margin-top: 5px;

        @media (max-width: @reflex-sm) {
            padding: 5px 10px;
        }
    }
}

#Block__VariantsList {
    .product__section {
        padding: 5px;
        @media (min-width: @reflex-sm) {
            padding: 30px;
        }
    }
}