/**
 * Basic typography style for text
 * Warning: These styles overrides without the default .dw-mod class, as they all target the most basic required elements
 */
h1 {
  text-transform: uppercase;
}
h2 {
  text-transform: uppercase;
}
h3 {
  font-size: 18px;
}
.light-font {
  font-family: "Palanquin";
  font-weight: 300;
}
.u-color-green-light h2 {
  font-family: "Palanquin";
  font-weight: 300;
  color: #2E6E40;
  text-transform: none;
}
.help-text {
  font-size: .9em;
}
@media (max-width: 768px) {
  .multiple-paragraphs-container .dw-mod h1 span {
    font-size: 38px !important;
  }
}
.breadcrumb.dw-mod {
  padding-top: 10px;
  padding-left: 10px;
}
.breadcrumb__item.dw-mod {
  font-size: 12px;
  font-weight: 300;
}
.breadcrumb__item.dw-mod a {
  color: #000;
}
.breadcrumb__item.dw-mod:last-child a {
  color: #a8a8a8;
}
.btn {
  margin-top: 20px;
  padding: 10px 30px;
  border-radius: 3px !important;
}
.btn--tag {
  color: #a8a8a8;
  border: 1px solid #a8a8a8;
}
.btn--tag:hover {
  background: #2E6E40;
  border-color: #2E6E40;
}
.btn--tag.checked {
  background: #2E6E40;
  border-color: #2E6E40;
}
.btn--primary.dw-mod {
  text-transform: uppercase;
  border: 2px solid;
  height: auto;
  line-height: 20px;
  padding: 10px 25px;
}
.btn--primary.dw-mod:hover {
  background: #2E6E40;
  color: #ffffff;
  border: 2px solid #2E6E40;
}
.btn--primary.dw-mod:active,
.btn--primary.dw-mod:focus {
  background-color: transparent !important;
  color: #2E6E40 !important;
  border-color: #2E6E40 !important;
  outline: none;
}
.btn--secondary.dw-mod {
  text-transform: uppercase;
  border: 2px solid #ffffff;
  height: auto;
  line-height: 20px;
  padding: 10px 25px;
}
.btn--secondary.dw-mod:hover {
  color: #333;
  background: #ffffff;
}
.btn--secondary.dw-mod:active,
.btn--secondary.dw-mod:focus {
  color: #333;
  background: #ffffff;
}
.btn--line-height:not(.btn--sm) {
  line-height: inherit !important;
  height: auto !important;
}
.btn--load-more {
  width: auto;
  margin: 10px auto;
  height: auto;
  padding: 18px 15px !important;
}
.btn--load-more:before {
  content: '\f0fe';
  font-family: FontAwesome;
  font-size: 17px;
  margin-right: 10px;
}
.btn--load-more:active,
.btn--load-more:focus {
  background-color: transparent !important;
  color: #2E6E40 !important;
  border-color: #2E6E40 !important;
  outline: none;
}
.product__price-buy-button.dw-mod {
  margin-top: 4px;
}
.footer.dw-mod {
  padding-top: 0;
}
.footer.dw-mod .center-container {
  padding: 3em 0 4em 0;
}
.footer.dw-mod .footer__heading.dw-mod {
  color: #2E6E40;
  font-family: 'Palanquin Dark';
  font-weight: 500;
  margin-bottom: 1.2rem;
  text-transform: uppercase;
}
.footer.dw-mod a {
  color: #242424;
}
.footer.dw-mod a:hover {
  color: #2E6E40;
}
.footer.dw-mod .fa-facebook-square {
  color: #3b5998;
}
@media (max-width: 992px) {
  .footer.dw-mod .grid {
    padding: 0 1em;
  }
}
.footer.dw-mod .grid > div:first-child {
  width: 50%;
}
@media (min-width: 768px) {
  .footer.dw-mod .grid > div:first-child {
    width: 25%;
  }
}
@media (min-width: 992px) {
  .footer.dw-mod .grid > div:first-child {
    width: 16.66666667%;
  }
}
.footer.dw-mod .grid > div:nth-child(2) {
  width: 50%;
}
@media (min-width: 768px) {
  .footer.dw-mod .grid > div:nth-child(2) {
    width: 25%;
  }
}
@media (min-width: 992px) {
  .footer.dw-mod .grid > div:nth-child(2) {
    width: 16.66666667%;
  }
}
.footer.dw-mod .grid > div:nth-child(3) {
  width: 100%;
}
@media (min-width: 768px) {
  .footer.dw-mod .grid > div:nth-child(3) {
    width: 25%;
  }
}
@media (min-width: 992px) {
  .footer.dw-mod .grid > div:nth-child(3) {
    width: 16.66666667%;
  }
}
.footer.dw-mod .grid > div:last-child {
  width: 100%;
}
@media (min-width: 768px) {
  .footer.dw-mod .grid > div:last-child {
    width: 25%;
  }
}
@media (min-width: 992px) {
  .footer.dw-mod .grid > div:last-child {
    width: 16.66666667%;
  }
}
.footer.dw-mod .brand-list-heading {
  padding-left: 0;
}
@media (min-width: 992px) {
  .footer.dw-mod .brand-list-heading {
    padding-left: 15px;
  }
}
.footer.dw-mod .brands-list .brand-images {
  flex-direction: row;
  float: left;
  width: 50%;
  max-height: 120px;
  padding: 15px;
}
.footer.dw-mod .brands-list .brand-images img {
  max-height: 110px;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .footer.dw-mod .brands-list .brand-images {
    max-height: 40px;
    padding: 10px;
  }
  .footer.dw-mod .brands-list .brand-images img {
    max-height: 40px;
    margin: 0 auto;
  }
}
@media (min-width: 992px) {
  .footer.dw-mod .brands-list .brand-images {
    min-height: 130px;
    padding: 20px;
    padding-left: 0;
  }
  .footer.dw-mod .brands-list .brand-images img {
    display: block;
    max-width: 100%;
    max-height: unset;
  }
}
.footer.dw-mod .brands-list .brand-images:nth-child(3),
.footer.dw-mod .brands-list .brand-images:nth-child(4) {
  margin-top: 10px;
}
.footer.dw-mod .footer-bottom {
  background: #2E6E40;
  color: #ffffff;
  padding: 1em;
}
.footer.dw-mod .footer-bottom p {
  margin-bottom: 0;
  font-size: 13px;
}
.header.mod {
  max-height: 45px;
}
.header-top.dw-mod {
  padding: 25px 0;
}
.page {
  background: #EDF4EF;
}
.grid__cell-img {
  display: block;
}
.content-container.dw-mod {
  background: inherit;
}
.multiple-paragraphs-container.dw-mod p a {
  color: #2E6E40;
}
@media (max-width: 992px) {
  .paragraph-container__grid--bleed-y {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.paragraph-container {
  padding-top: 2em;
  padding-bottom: 2em;
  /*padding-left: 0.5em;
    padding-right: 0.5em;*/
}
@media (max-width: 992px) {
  .paragraph-container {
    padding-top: 2.5em;
    padding-bottom: 2.5em;
    padding-left: 1em;
    padding-right: 1em;
  }
}
.center-container--with-background-image {
  min-height: 450px;
}
.center-container--with-background-image h1 {
  font-size: 32px;
  line-height: 40px;
}
.center-container--with-background-image p {
  font-size: 16px;
  line-height: 22px;
}
.item-center {
  margin: 0 auto;
}
.header-and-blocks .paragraph-container {
  padding-top: 1em;
}
.header-and-blocks .paragraph-container:first-child {
  padding-top: 4.5em;
  padding-bottom: 0;
}
.u-color-dark-green--bg {
  background: #2E6E40;
}
.logo.dw-mod {
  float: left;
  width: 50%;
}
@media (min-width: 1200px) {
  .logo.dw-mod {
    width: auto;
    float: none;
    margin-right: 65px;
    padding-left: 0;
  }
}
.logo--mobile.dw-mod {
  width: 100%;
}
.menu-mobile__trigger--back {
  top: 39px;
}
.menu-mobile__submenu .menu-mobile__item:nth-child(1) {
  background: transparent;
}
.menu-mobile__submenu .menu-mobile__item:nth-child(1) .menu-mobile__link {
  border-bottom: none !important;
}
.menu-mobile__submenu .menu-mobile__item:nth-child(1) .menu-mobile__trigger--back:before {
  margin: -32px auto 1px;
  font-size: 18px;
}
.menu-mobile__submenu .menu-mobile__item:nth-child(2) {
  margin-top: -15px;
}
.main-navigation.dw-mod {
  padding: 0;
}
@media (min-width: 768px) {
  .main-navigation.dw-mod {
    padding: 15px;
  }
}
@media (min-width: 1200px) {
  .main-navigation.dw-mod {
    padding: 0;
  }
}
.menu-nav {
  position: absolute;
  right: 0;
  top: 0;
}
@media (min-width: 768px) {
  .menu-nav {
    top: -30px;
  }
}
@media (min-width: 1200px) {
  .menu-nav {
    float: left;
    position: initial;
  }
}
.menu-nav .menu.dw-mod {
  padding-top: 25px;
}
@media (min-width: 1200px) {
  .menu-nav .menu.dw-mod {
    padding-top: 35px;
  }
}
.top-container__center-container .search {
  float: left;
  margin-left: 25px;
}
@media (min-width: 768px) {
  .top-container__center-container .search {
    position: absolute;
    right: 55px;
    top: -29px;
  }
}
@media (min-width: 1200px) {
  .top-container__center-container .search {
    top: 0;
  }
}
.top-container__center-container .search i {
  font-size: 20px;
  margin-left: 30px;
}
@media (min-width: 1200px) {
  .top-container__center-container .search i {
    font-size: 18px;
  }
}
.top-container__center-container .search .menu.dw-mod {
  padding-top: 30px;
}
@media (min-width: 1200px) {
  .top-container__center-container .search .menu.dw-mod {
    padding-top: 35px;
  }
}
.menu__item--top-level.dw-mod {
  padding: 0 15px;
  border: none;
}
.menu__item--top-level.dw-mod:hover {
  border: none;
}
.is-mega.dw-mod:after {
  content: '\f0d7';
}
.mega-menu {
  padding: 30px !important;
  box-shadow: none;
}
.mega-menu .mega-menu__link.dw-mod {
  font-family: 'Palanquin';
  padding: .5em .4em;
}
.mega-menu .menu.dw-mod {
  padding-top: 0;
}
.mega-menu .mega-menu__header-container.dw-mod {
  margin: 0;
}
.mega-menu .mega-menu__header-container__text.dw-mod {
  font-family: "Palanquin Dark";
  margin-bottom: 0;
}
.mega-menu .mega-menu__header-container__text.dw-mod:hover {
  color: #134647;
}
.mega-menu.small-bg {
  left: auto;
  max-width: 275px;
  margin-left: -15px;
}
.mega-menu .splitted {
  width: 100%;
  padding: 10px 0;
}
.mega-menu .splitted:first-child {
  padding-top: 0;
}
.menu__item--active.dw-mod,
.menu__item.dw-mod:hover,
.menu__item.dw-mod:focus,
.menu__item.dw-mod:active {
  border: none !important;
}
.top-micro-search.dw-mod {
  padding-top: 0 !important;
  width: 100vw;
  top: 107%;
  padding-bottom: 0;
  left: 6px;
}
@media (min-width: 768px) {
  .top-micro-search.dw-mod {
    top: 132%;
    left: -7px;
  }
}
@media (min-width: 1200px) {
  .top-micro-search.dw-mod {
    top: 105%;
    width: 370px;
    right: 85px;
    left: auto;
  }
}
.top-micro-search.dw-mod .typeahead {
  margin: 0;
}
.typeahead-search-field input[type=text] {
  height: 45px !important;
}
.typeahead-search-field .dropdown__item--seperator.dw-mod {
  border-bottom: 0;
  border-top: 1px solid #e4e4e4;
  padding: 0.5em;
  text-transform: none;
  font-family: Palanquin;
}
.typeahead-search-field .dropdown__item--seperator.dw-mod:hover {
  background: #EDF4EF;
}
.typeahead-search-field .dropdown__item--seperator.dw-mod a {
  margin-top: 8px;
}
.typeahead-search-field .dropdown__item--seperator.dw-mod .btn {
  margin-top: 0;
}
.typeahead-search-field .dropdown__item--seperator.dw-mod i {
  margin-left: 0;
}
.typeahead-search-field .dropdown__item--not-selectable.dw-mod {
  padding-top: 15px;
  border-top: 1px solid #e4e4e4;
}
.typeahead-search-field .dropdown__item--not-selectable.dw-mod:hover {
  background: #EDF4EF;
}
.typeahead-search-field .dropdown__item--not-selectable.dw-mod .btn {
  padding: 20px 0;
  margin-top: 0;
}
.typeahead-search-field .dropdown__item--not-selectable.dw-mod .btn:hover {
  background: #2E6E40;
  color: #FFF;
}
.typeahead-search-field .no-results {
  padding: 0.5em;
  text-transform: none;
  font-family: "Palanquin";
}
.js-typeahead-enter-btn {
  margin-top: 0 !important;
  background-color: #2E6E40 !important;
  color: #ffffff !important;
  border-color: #2E6E40 !important;
}
.typeahead-mobile__search-field.dw-mod input {
  width: 70%;
}
.typeahead-mobile__search-field.dw-mod .btn {
  line-height: 14px;
}
.product {
  background: #ffffff;
  padding: 35px;
}
@media (max-width: 992px) {
  .product {
    padding: 15px;
  }
}
.product__top {
  background-color: transparent !important;
}
.product__top .center-container {
  max-width: 1270px;
}
.product__top .center-container div:not(.carousel-next-btn):not(.carousel-prev-btn) {
  background-color: #ffffff;
}
.product__info .grid:nth-child(2) {
  padding: 15px;
}
@media (min-width: 992px) {
  .product__info .grid:nth-child(2) {
    padding: unset;
  }
}
.product__title {
  padding-top: 25px;
  width: 100%;
}
.product__title h1 {
  color: #2E6E40 !important;
}
.product__section {
  background-color: #ffffff;
  padding: 30px;
}
.product__section .center-container .grid {
  padding: 0 !important;
}
.product .labels {
  top: -14px;
  right: 0;
  width: 100%;
  left: auto;
}
@media (min-width: 768px) {
  .product .labels {
    width: 30%;
    top: -13px;
  }
}
@media (min-width: 1200px) {
  .product .labels {
    width: 18%;
    top: -13px;
  }
}
.product .product__thumbs.dw-mod {
  background: #ffffff;
}
.product .product__thumbs.dw-mod .thumb-list__item:hover {
  border: 1px solid #d3d3d3;
}
.product .product__thumbs.dw-mod .thumb-list__item--active {
  border: 1px solid #d3d3d3;
}
.product .product__title.dw-mod {
  margin-bottom: 15px;
}
.product .product__info.dw-mod {
  padding: 5px;
}
@media (min-width: 992px) {
  .product .product__info.dw-mod {
    padding: 15px;
  }
}
.item-number.dw-mod {
  color: #a8a8a8 !important;
  margin-bottom: 0.25em;
  font-size: 1.5rem;
  margin-top: 5px;
}
.variant-table.dw-mod td {
  padding: 5px 0;
}
.variant-table.dw-mod input[type=number] {
  width: 60x;
  max-width: 60px;
}
.variant-table.dw-mod .btn--primary {
  margin-top: 5px;
}
@media (max-width: 768px) {
  .variant-table.dw-mod .btn--primary {
    padding: 5px 10px;
  }
}
#Block__VariantsList .product__section {
  padding: 5px;
}
@media (min-width: 768px) {
  #Block__VariantsList .product__section {
    padding: 30px;
  }
}
@media (max-width: 992px) {
  .cart-table.dw-mod .form__field-group .btn {
    color: #920202 !important;
    margin-top: 0;
    padding: 2px 7px;
    font-size: 12px;
    margin-left: 5px;
  }
  .cart-table.dw-mod .u-hidden {
    visibility: hidden;
  }
  .cart-table.dw-mod td {
    border: none !important;
  }
  .cart-table.dw-mod tr {
    float: left;
    padding: 15px;
  }
  .cart-table.dw-mod tr:nth-child(3n+1) {
    width: 60%;
  }
  .cart-table.dw-mod .table__row--no-border {
    width: 30%;
  }
  .cart-table.dw-mod .table__row--no-border .cart-table__image.dw-mod {
    width: 0;
    padding: 0;
    display: none;
  }
  .cart-table.dw-mod .total-price {
    font-size: 18px;
    font-weight: bold;
  }
}
.cart-orderline__cell .btn {
  color: #920202 !important;
  margin-top: 0;
  padding: 2px 7px;
  font-size: 12px;
  margin-left: 5px;
}
.voucher-input div {
  float: left;
  max-width: 150px;
  margin-right: 5px;
}
.voucher-input input {
  max-width: 150px;
}
.btn-voucher {
  padding: 6px 10px !important;
  min-width: 100px;
  margin-top: 0;
  float: left;
}
.table--responsive-order {
  background-color: white;
  margin-bottom: 0 !important;
}
#Block__Shipping .form__field-group label:before {
  margin-top: 3px;
}
#Block__Shipping .form__field-group label:after {
  top: 8px;
}
#Block__Shipping .shipping-option-title {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 5px;
  margin-left: 5px;
}
#Block__Shipping .shipping-option-description {
  display: block;
  margin-left: 27px;
}
.mini-cart__counter.dw-mod {
  background-color: #2E6E40;
  line-height: 13px;
}
.mini-cart__footer.dw-mod .btn--secondary {
  border-color: #920202;
  color: #920202;
}
.mini-cart__footer.dw-mod .btn--secondary:hover {
  background-color: #920202;
  color: white;
}
.product-group-header,
.product-group-count {
  text-align: center;
  text-transform: uppercase;
}
.product-group-header {
  margin-bottom: 15px;
}
.product-group-count {
  text-transform: none;
  margin-bottom: 50px;
  font-family: "Palanquin";
  font-weight: 300;
  color: #222;
  font-size: 18px;
}
.product-list.dw-mod {
  margin-top: 1.2em;
}
.product-list.dw-mod > div {
  padding: 1em;
}
.product-list__list-item.dw-mod .variant-heading {
  margin-bottom: 10px;
  font-weight: bold;
}
.product-list__list-item.dw-mod .labels {
  top: 0;
}
.product-list__list-item.dw-mod .product__name {
  float: left;
  text-align: left;
}
@media (max-width: 992px) {
  .product-list__list-item.dw-mod .product__name {
    text-align: center;
    float: none;
  }
}
.product-list__list-item.dw-mod .product-list__list-item__right .help-text {
  text-align: center;
}
@media (min-width: 1200px) {
  .product-list__list-item.dw-mod .product-list__list-item__right .help-text {
    text-align: left;
  }
}
.product-list__list-item__left.dw-mod {
  border: none;
}
.product-list__list-item__right.dw-mod {
  border: none;
}
.product-list__grid-item.dw-mod {
  transition: none !important;
}
.product-list__grid-item.dw-mod:hover .product-image {
  border-color: #fff !important;
}
.product-list__grid-item.dw-mod:hover .product-list__grid-item__price-info.dw-mod,
.product-list__grid-item.dw-mod:hover .product-list__grid-item__image.dw-mod {
  border-color: #fff !important;
}
.product-list__grid-item.dw-mod .product-list__grid-item__price-info.dw-mod h6 {
  font-size: 18px;
}
.product-card {
  border: 1px solid transparent;
  padding: 15px;
  transition: all 0.4s;
  background: #ffffff;
}
.product-card:hover {
  border-color: #2E6E40;
}
.product-image {
  padding-top: 10px;
  border-color: #fff;
  transition: none !important;
}
.promotion-product .product-list__grid-item__price-info.dw-mod h2 {
  font-size: 18px;
  margin-top: 25px;
  color: #333;
  margin-bottom: 5px;
  text-transform: none;
}
@media (min-width: 992px) {
  .promotion-product .product-list__grid-item__price-info.dw-mod h2 {
    font-size: 24px;
  }
}
.labels {
  position: absolute;
  background-color: transparent !important;
  z-index: 1;
  top: -14px;
  right: 0;
  width: 100%;
  left: auto;
}
@media (min-width: 768px) {
  .labels {
    width: 30%;
    top: -13px;
  }
}
@media (min-width: 1200px) {
  .labels {
    width: 18%;
    top: -13px;
  }
}
.labels.list-view {
  position: absolute;
  top: 0;
  left: 14px;
  z-index: 10;
  width: 94%;
  text-align: center;
}
@media (max-width: 992px) {
  .labels.list-view {
    top: 0;
  }
}
.labels .product__flag {
  color: #ffffff;
  font-family: "Palanquin Dark";
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  padding: 5px 15px;
  text-align: center;
  border-radius: 4px;
  z-index: 2;
  display: inline-block;
  margin: 0 auto;
}
.labels .product__flag.news {
  background: #B6B83C !important;
}
.labels .product__flag.campaign {
  background: #641D31 !important;
}
.labels .product__flag.u-hidden {
  display: none;
}
.product-list__grid-item__image.dw-mod {
  background: #ffffff;
  border: none;
  /*height: 280px;
    max-height: 280px;*/
  overflow: hidden;
}
.product-list__grid-item__price-info.dw-mod {
  border: none;
  height: unset;
}
.product-list__grid-item__price-info.dw-mod h3 {
  margin-top: 20px;
  color: #333;
  margin-bottom: 5px;
}
.collection .btn--tag {
  background: transparent;
  color: #2E6E40;
  border-color: #2E6E40;
}
.collection .btn--tag:hover {
  background: #2E6E40;
  color: #ffffff;
}
.filters {
  margin-top: 160px;
}
.filters .expand-container {
  background: transparent;
  border: none;
}
.filters .expand-container .facets-container__header {
  border: none;
  font-family: "Palanquin Dark";
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  color: #2E6E40;
  pointer-events: none;
  padding-left: 6px;
}
.filters .expand-container .facets-container__header:after {
  display: none;
}
.filters .expand-container .facets-container__list {
  max-height: none !important;
  font-size: 16px;
  font-weight: 300;
}
.filters .expand-container .facets-container__list .checkbox-facet.dw-mod:before {
  border-color: #2E6E40;
}
.filters .expand-container .facets-container__list input[type="checkbox"].form__control:checked + label:after {
  content: "\f14a";
  font-family: FontAwesome;
  position: absolute;
  top: 0;
  color: #2E6E40;
  left: 0;
  font-size: 23px;
  height: unset;
  width: unset;
  border: none;
  transform: none;
}
.filters .expand-container .facets-container__list .checkbox-facet__label.dw-mod {
  flex: unset;
  margin-right: 10px;
}
.filters .expand-container .facets-container__list .checkbox-facet__count.dw-mod {
  color: #333;
}
.filters-mobile .dropdown {
  background: #fcfffd;
  border: 1px solid #2E6E40;
  margin-bottom: 0;
  border: none;
}
.filters-mobile .dropdown .dropdown__header {
  font-family: "Palanquin Dark";
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  color: #2E6E40;
  border: 1px solid #2E6E40;
  padding: 12px 15px;
  height: auto;
}
.filters-mobile .dropdown .dropdown__header:after {
  font-size: 26px;
}
.filters-mobile .dropdown .dropdown__content {
  position: relative;
  box-shadow: none;
  max-height: none;
  border: 1px solid #2E6E40;
  border-top: none;
  padding: 17px 15px 10px 15px;
}
.filters-mobile .dropdown .dropdown__content > div {
  position: relative;
}
.filters-mobile .dropdown .dropdown__content label {
  cursor: pointer;
  position: absolute;
  width: 18px;
  height: 18px;
  top: 0;
  left: 0;
  background: transparent;
  border: 1px solid #2E6E40;
  border-radius: 2px;
}
.filters-mobile .dropdown .dropdown__content label:after {
  opacity: 0;
  content: "\f14a";
  font-family: FontAwesome;
  position: absolute;
  top: -2px;
  color: #2E6E40;
  left: -2px;
  font-size: 23px;
}
.filters-mobile .dropdown .dropdown__content .name {
  margin-left: 10px;
}
.filters-mobile .dropdown .dropdown__content .product-count {
  margin-left: 5px;
}
.filters-mobile .dropdown .dropdown__content input[type="checkbox"] {
  visibility: hidden;
}
.filters-mobile .dropdown .dropdown__content input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
.filters-mobile .dropdown .dropdown__content .disabled {
  display: none;
}
.expand-trigger:checked + .expand-container .expand-container__content--limit-height {
  max-height: none;
}
.facet-selector {
  background-color: #2E6E40 !important;
  color: #ffffff !important;
  width: 97%;
  margin: 15px auto;
  padding: 25px !important;
  font-family: "Palanquin Dark";
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  border: none !important;
}
#selectedFacets .btn--tag {
  color: #2E6E40;
  background: transparent;
  border: none;
  margin: 10px 15px 10px 0;
}
#selectedFacets .btn--tag i {
  margin-left: 5px;
}
#selectedFacets .btn--tag:hover {
  background: transparent;
  border: none;
  color: #134647;
}
#selectedFacets .btn--tag:active,
#selectedFacets .btn--tag:focus {
  border: none;
  outline: none;
}
.btn--reset-filters {
  background: #2E6E40;
  color: #ffffff;
  border: 1px solid #2E6E40;
  padding: 0 15px;
}
.btn--reset-filters i {
  margin-left: 5px;
}
.btn--reset-filters:hover {
  background: #134647;
  border: 1px solid #134647;
}
.map-container {
  height: 600px;
}
.find-store {
  background: #ffffff;
  padding: 45px 40px;
  margin: 35px 0;
}
@media (max-width: 480px) {
  .find-store {
    margin-bottom: 0;
  }
}
.find-store .search-form {
  margin-bottom: 25px;
  width: 95%;
  margin: 0 auto;
  position: relative;
}
.find-store .search-form .button-primary {
  background: transparent;
  border: none;
  font-size: 20px;
  position: absolute;
  right: 1px;
  top: 6px;
  color: #134647;
}
.find-store .search-form .button-primary:active,
.find-store .search-form .button-primary:focus,
.find-store .search-form .button-primary:hover {
  outline: none;
}
.find-store .search-form .search-field {
  border: none;
  border-bottom: 2px solid #134647;
  margin-bottom: 10px;
  font-size: 18px;
}
.find-store .search-form .search-field:focus,
.find-store .search-form .search-field:active {
  outline: none;
}
.find-store .search-form .search-field::-webkit-input-placeholder {
  font-size: 18px;
  color: #333;
}
.find-store .search-form .search-field::-moz-placeholder {
  font-size: 18px;
  color: #333;
}
.find-store .search-form .search-field:-ms-input-placeholder {
  font-size: 18px;
  color: #333;
}
.find-store .search-form .search-field:-moz-placeholder {
  font-size: 18px;
  color: #333;
}
.find-store .search-form .address {
  text-align: center;
}
.find-store .search-form .formatted-address {
  text-transform: uppercase;
  font-size: 13px;
}
.find-store .stores {
  padding: 0 25px;
  margin-top: 30px;
}
@media (max-width: 992px) {
  .find-store .stores {
    padding: 0;
  }
}
.find-store .row-headings {
  text-align: left;
}
@media (max-width: 480px) {
  .find-store .row-headings {
    display: none;
  }
}
.find-store .search-filter {
  list-style: none;
  padding-left: 0;
  text-align: center;
  margin-bottom: 45px;
  margin-top: 35px;
  display: none;
}
.find-store .search-filter li {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 5px;
  position: relative;
  font-size: 14px;
  text-transform: uppercase;
}
.find-store .search-filter li label {
  cursor: pointer;
  margin-right: 5px;
}
.find-store .search-filter li label .check {
  border-radius: 2px;
  margin-right: 7px;
  border: 1px solid #000;
  padding: 0 3px;
  font-size: 9px;
  position: absolute;
  left: -10px;
  top: 2px;
  width: 16px;
  height: 16px;
}
.find-store .search-filter li label .check:before {
  content: '\f0c8';
  font-family: FontAwesome;
  color: #FFF;
}
.find-store .search-filter li label input[type=checkbox] {
  visibility: hidden;
}
.find-store .search-filter li label input[type=checkbox]:checked + .check:before {
  content: '\f00c';
  font-family: FontAwesome;
  color: #000;
  position: absolute;
  font-size: 12px;
  left: 1px;
  top: -2px;
}
.find-store .stores-list {
  margin-top: 20px;
  list-style: none;
  padding: 0;
  text-align: left;
}
.find-store .stores-list .store-name {
  font-size: 15px;
}
.find-store .stores-list .phonenumber {
  font-size: 14px;
}
.find-store .stores-list .phonenumber:before {
  content: '\f095';
  margin-right: 5px;
}
.find-store .stores-list > div {
  padding-bottom: 15px;
  margin-bottom: 25px;
  border-bottom: 2px solid #EDF4EF;
  overflow: hidden;
}
.find-store .stores-list > div:last-child {
  border-bottom: 0;
}
.find-store .stores-list > div > div {
  margin: 5px 0;
}
.find-store .stores-list > div span {
  display: inline-block;
}
.find-store .stores-list > div strong {
  display: inline-block;
  vertical-align: top;
  margin-right: 30px;
  min-width: 100px;
}
.find-store .stores-list > div .button {
  display: inline-block;
  float: left;
  margin: 15px 0 0 15px;
  padding: 7px 25px 7px 20px;
  border: 1px solid black;
  font-size: 14px;
  text-transform: uppercase;
}
.find-store .stores-list > div .button:hover {
  text-decoration: none;
  color: white;
  background: black;
}
.find-store .stores-list > div .button:before {
  content: '\f015';
  font-family: FontAwesome;
  margin-right: 8px;
}
.find-store .stores-list > div .tags {
  font-size: 0;
  margin-top: 15px;
}
.find-store .stores-list > div .tags b {
  font-size: 16px;
  margin-right: 15px;
}
.find-store .stores-list > div .tags .tag {
  margin-right: 7px;
  font-size: 12px;
}
.find-store .stores-list > div .tags .tag:not(:last-child):after {
  content: '-';
  margin-left: 7px;
}
.webshops {
  padding: 45px 40px;
  background: #ffffff;
  margin: 35px 0;
}
@media (max-width: 768px) {
  .webshops {
    padding: 0 25px;
  }
}
@media (max-width: 480px) {
  .webshops {
    margin: 0;
  }
}
.webshops ul {
  list-style: none;
  width: 80%;
}
@media (max-width: 480px) {
  .webshops ul {
    width: 100%;
  }
}
.webshops ul li {
  padding: 30px 0;
  margin-bottom: 0;
  border-bottom: 2px solid #EDF4EF;
  position: relative;
}
.webshops ul li a:after {
  content: '\f101';
  font-family: FontAwesome;
  font-size: 24px;
  border: 1px solid #2E6E40;
  padding: 7px 10px;
  border-radius: 3px;
  position: absolute;
  right: 0;
  transition-duration: 200ms;
}
.webshops ul li a:hover:after {
  color: #ffffff;
  background: #2E6E40;
}
.webshops ul li .btn {
  float: right;
  margin-bottom: 0;
  margin-top: -10px;
  padding: 18px 30px;
}
.webshops ul li:last-child {
  border-bottom: none;
}
/* Media Queries
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/*
Note: The best way to structure the use of media queries is to create the queries
near the relevant code. For example, if you wanted to change the styles for buttons
on small devices, paste the mobile query code up in the buttons section and style it
there.
*/
